import URLSafeBase64 from "urlsafe-base64";
import {Buffer} from "buffer";

class AppleMobileConfig {

  //
  // buildAndSign(filterId, deviceId, deviceName) {
  //
  //   let encodedDeviceId = URLSafeBase64.encode(Buffer.from(deviceId, 'utf8'))
  //
  //   let hostname = `${process.env.VUE_APP_ENV === 'prod' ? 'doh' : 'devdoh'}.truple.workers.dev`
  //   let path = `/${filterId}/${encodedDeviceId}/dns-query`
  //
  //   let result = `https://${hostname}${path}`
  //
  //   let deviceCheckUrl = `${process.env.VUE_APP_DOH_URL}deviceIdCheck?deviceId=${encodeURIComponent(deviceId)}`
  //
  //   let name = `DNS over HTTPS - ${deviceName}`
  //
  //   let serverName = `Truple DOH - ${filterId} - ${deviceId}`
  //
  //   let config = [{
  //     PayloadType: 'com.apple.dnsSettings.managed',
  //     PayloadVersion: 1,
  //     PayloadIdentifier: 'com.camhart.netcountable.8A4244E4-7802-46D9-9BA9-06EA71975740',
  //     PayloadUUID: '8E0D6F57-0EB4-4C89-A068-2D6EE5FAC976',
  //     PayloadDisplayName: name,
  //     PayloadDescription: 'Configures device to use Truple Internet Accountability',
  //     PayloadOrganization: 'Truple LLC',
  //     PayloadContent: {
  //       Name: 'DNS over HTTPS - Truple',
  //       PayloadDescription: 'Configures device to use Truple Internet Accountability',
  //       PayloadDisplayName: name,
  //       PayloadIdentifier: 'com.apple.dnsSettings.managed.AFCA1444-5AEB-44CD-B23D-5D1B3ADCD1EE',
  //       PayloadType: ''
  //
  //     }
  //   }]
  //
  //   let certAndKey = {
  //     key: '',
  //     cert: ''
  //   }
  // }



  build(
    filterId, deviceId, deviceName, supervised
  ) {
    let encodedDeviceId = URLSafeBase64.encode(Buffer.from(deviceId, 'utf8'))

    let hostname = `${process.env.VUE_APP_ENV === 'prod' ? 'doh' : 'devdoh'}.truple.workers.dev`
    let path = `/${filterId}/${encodedDeviceId}/dns-query`

    let result = `https://${hostname}${path}`

    let deviceCheckUrl = `${process.env.VUE_APP_DOH_URL}deviceIdCheck?deviceId=${encodeURIComponent(deviceId)}`

    let name = `DNS over HTTPS - ${deviceName}`

    let serverName = `${process.env.VUE_APP_NAME} DOH - ${filterId} - ${deviceId}`

    /**
     * This string below contains support to whitelist popular domains, but it doesn't appear to work, and when added
     * it prevents the URLStringProbe from working.
     *
     * `<?xml version="1.0" encoding="UTF-8"?>
     <!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
     <plist version="1.0">
     <dict>
     <key>PayloadContent</key>
     <array>
     <dict>
     <key>Name</key>
     <string>DNS over HTTPS - Truple</string>
     <key>PayloadDescription</key>
     <string>Configures device to use Truple Internet Accountability</string>
     <key>PayloadDisplayName</key>
     <string>${name}</string>
     <key>PayloadIdentifier</key>
     <string>com.apple.dnsSettings.managed.AFCA1444-5AEB-44CD-B23D-5D1B3ADCD1EE</string>
     <key>PayloadType</key>
     <string>com.apple.dnsSettings.managed</string>
     <key>PayloadUUID</key>
     <string>8E0D6F57-0EB4-4C89-A068-2D6EE5FAC976</string>
     <key>PayloadVersion</key>
     <integer>1</integer>
     <key>DNSSettings</key>
     <dict>
     <key>DNSProtocol</key>
     <string>HTTPS</string>
     <key>ServerURL</key>
     <string>${result}</string>
     <key>ServerName</key>
     <string>${serverName}</string>
     </dict>
     <key>OnDemandRules</key>
     <array>
     <dict>
     <key>Action</key>
     <string>EvaluateConnection</string>
     <key>ActionParameters</key>
     <array>
     <dict>
     <key>DomainAction</key>
     <string>NeverConnect</string>
     <key>Domains</key>
     <array>
     <string>apple.com</string>
     <string>*.apple.com</string>
     <string>appstore.com</string>
     <string>icloud.com</string>
     <string>www.icloud.com</string>
     <string>*.icloud.com</string>
     <string>cdn-apple.com</string>
     <string>mzstatic.com</string>
     <string>entrust.net</string>
     <string>digicert.com</string>
     <string>verisign.net</string>
     <string>amazonaws.com</string>
     <string>*.amazonaws.com</string>
     <string>*.elb.amazonaws.com</string>
     <string>calendar.yahoo.com</string>
     <string>mail.yahoo.com</string>
     <string>adswizz.com</string>
     <string>brightcove.com</string>
     <string>parsely.com</string>
     <string>iris.tv</string>
     <string>dwcdn.net</string>
     <string>segment.com</string>
     <string>adnxs.com</string>
     <string>appnexusgslb.net</string>
     <string>doubleclick.net</string>
     <string>*.doubleclick.net</string>
     <string>gstatic.com</string>
     <string>akamai.net</string>
     <string>google-analytics.com</string>
     <string>apple-dns.net</string>
     <string>*.apple-dns.net</string>
     <string>*.yahoodns.net</string>
     <string>*.oath.cloud</string>
     <string>*.googlesyndication.com</string>
     <string>*.akadns.net</string>
     <string>akamaiedge.net</string>
     <string>*.akamaiedge.net</string>
     <string>*.cloudfront.net</string>
     <string>*.fbcdn.net</string>
     <string>*.doubleverify.net</string>
     <string>ampproject.org</string>
     <string>*.ampproject.org</string>
     <string>casalemedia.com</string>
     <string>*.casalemedia.com</string>
     <string>amazon-adsystem.com</string>
     <string>*.amazon-adsystem.com</string>
     <string>rtk.io</string>
     <string>*.rtk.io</string>
     <string>*.googleapis.com</string>
     <string>icloud-content.com</string>
     <string>*.icloud-content.com</string>
     <string>*.33across.com</string>
     </array>
     </dict>
     </array>
     </dict>
     <dict>
     <key>Action</key>
     <string>Disconnect</string>
     <key>DNSDomainMatch</key>
     <array>
     <string>*.apple.com</string>
     <string>*.icloud.com</string>
     <string>*.appstore.com</string>
     <string>*.cdn-apple.com</string>
     <string>*.mzstatic.com</string>
     <string>*.entrust.net</string>
     <string>*.digicert.com</string>
     <string>*.verisign.net</string>
     <string>*.amazonaws.com</string>
     <string>*.elb.amazonaws.com</string>
     <string>*.calendar.yahoo.com</string>
     <string>*.mail.yahoo.com</string>
     <string>*.adswizz.com</string>
     <string>*.brightcove.com</string>
     <string>*.parsely.com</string>
     <string>*.iris.tv</string>
     <string>*.dwcdn.net</string>
     <string>*.segment.com</string>
     <string>*.adnxs.com</string>
     <string>*.appnexusgslb.net</string>
     <string>*.doubleclick.net</string>
     <string>*.gstatic.com</string>
     <string>*.akamaiedge.net</string>
     <string>*.akamai.net</string>
     <string>*.google-analytics.com</string>
     <string>*.apple-dns.net</string>
     <string>*.33across.com</string>
     <string>*.akadns.net</string>
     <string>*.openx.net</string>
     <string>*.googleapis.com</string>
     <string>*.aaplimg.com</string>
     <string>*.yahoodns.net</string>
     <string>*.weather.com</string>
     <string>*.dca0.com</string>
     <string>*.taboola.com</string>
     <string>*.ampproject.org</string>
     <string>*.doubleverify.com</string>
     <string>*.newrelic.com</string>
     <string>*.emogi.com</string>
     </array>
     </dict>
     <dict>
     <key>Action</key>
     <string>Connect</string>
     <key>URLStringProbe</key>
     <string>${deviceCheckUrl}</string>
     </dict>
     <dict>
     <key>Action</key>
     <string>Disconnect</string>
     </dict>
     </array>
     </dict>
     </array>
     <key>PayloadDescription</key>
     <string>DNS over Https settings</string>
     <key>PayloadDisplayName</key>
     <string>DNS over HTTPs</string>
     <key>PayloadIdentifier</key>
     <string>com.camhart.netcountable.8A4244E4-7802-46D9-9BA9-06EA71975740</string>
     <key>PayloadRemovalDisallowed</key>
     <false/>
     <key>PayloadType</key>
     <string>Configuration</string>
     <key>PayloadUUID</key>
     <string>2066759F-6CB2-40CE-AA24-C26C4F656B71</string>
     <key>PayloadVersion</key>
     <integer>1</integer>
     </dict>
     </plist>`
     */

    return `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE plist PUBLIC "-//Apple//DTD PLIST 1.0//EN" "http://www.apple.com/DTDs/PropertyList-1.0.dtd">
<plist version="1.0">
<dict>
  <key>PayloadContent</key>
  <array>
    <dict>
      <key>Name</key>
      <string>DNS over HTTPS - Truple</string>
      <key>PayloadDescription</key>
      <string>Configures device to use Truple Internet Accountability</string>
      <key>PayloadDisplayName</key>
      <string>${name}</string>
      <key>PayloadIdentifier</key>
      <string>com.apple.dnsSettings.managed.AFCA1444-5AEB-44CD-B23D-5D1B3ADCD1EE</string>
      <key>PayloadType</key>
      <string>com.apple.dnsSettings.managed</string>
      <key>PayloadUUID</key>
      <string>8E0D6F57-0EB4-4C89-A068-2D6EE5FAC976</string>
      <key>PayloadVersion</key>
      <integer>1</integer>
      <key>DNSSettings</key>
      <dict>
          <key>DNSProtocol</key>
          <string>HTTPS</string>
          <key>ServerURL</key>
          <string>${result}</string>
          <key>ServerName</key>
          <string>${serverName}</string>
      </dict>
      <key>OnDemandRules</key>
      <array>
        <dict>
          <key>Action</key>
          <string>Connect</string>
          <key>URLStringProbe</key>
          <string>${deviceCheckUrl}</string>
        </dict>
        <dict>
          <key>Action</key>
          <string>Disconnect</string>
        </dict>
      </array>
      <key>ProhibitDisablement</key>
      <${supervised ? 'true' : 'false'}/>
    </dict>
  </array>
  <key>PayloadDescription</key>
  <string>DNS over Https settings</string>
  <key>PayloadDisplayName</key>
  <string>DNS over HTTPs</string>
  <key>PayloadIdentifier</key>
  <string>com.camhart.netcountable.8A4244E4-7802-46D9-9BA9-06EA71975740</string>
  <key>PayloadRemovalDisallowed</key>
  <${supervised ? 'true' : 'false'}/>
  <key>PayloadScope</key>
  <string>System</string>
  <key>PayloadType</key>
  <string>Configuration</string>
  <key>PayloadUUID</key>
  <string>2066759F-6CB2-40CE-AA24-C26C4F656B71</string>
  <key>PayloadVersion</key>
  <integer>1</integer>
</dict>
</plist>`
  }

}

export default (new AppleMobileConfig())