class DNSCloakHelper {

  getInstructionsLink(filterId, deviceId, deviceName) {
    let instructionsLink = `${process.env.VUE_APP_HOST}/dnscloak?filterId=${encodeURIComponent(filterId)}&deviceId=${encodeURIComponent(deviceId)}&deviceName=${encodeURIComponent(deviceName)}`
    return instructionsLink
  }

  getMailLink(filterId, deviceId, deviceName) {
    let instructionsLink = this.getInstructionsLink(filterId, deviceId, deviceName)

    let subject = encodeURIComponent('Truple iPhone/iPad Installation Instructions')
    let body = encodeURIComponent(
      `Go to ${instructionsLink} to view instructions for installing Truple's internet accountability and optional filtering on your iOS device.`
    )

    return `mailto:?subject=${subject}&body=${body}`
  }
}

export default (new DNSCloakHelper())