import { Buffer } from 'buffer'
import URLSafeBase64 from 'urlsafe-base64'
import filters from "../Filters";


class SdnsStamp {

  calculateConfig(filterId, deviceId, deviceName) {

    deviceName = deviceName.replace(/[^0-9a-zA-Z_() ]/gi, '')

    console.log(`${filterId} ${deviceId} ${deviceName}`)

    let fallbackResolver = filters.filter(x => { return x.id === filterId})[0].fallbackResolver;

    return `server_names = ["${process.env.VUE_APP_NAME} (${deviceName})"]
listen_addresses = ["127.0.0.1:53", "[::1]:53"]
max_clients = 250
ipv4_servers = true
ipv6_servers = false
doh_servers = true
force_tcp = false
fallback_resolver = "${fallbackResolver}"
ignore_system_dns = true
cache = true
cache_size = 2048
cache_min_ttl = 3600
cache_max_ttl = 86400
cache_neg_ttl = 1200
[static]
[static."${process.env.VUE_APP_NAME} (${deviceName})"]
stamp = "${this.calculateStamp(filterId, deviceId)}"`
  }

  calculateStamp(filterId, deviceId) {
    // see https://github.com/jedisct1/vue-dnsstamp/blob/master/src/components/DNSStamp.vue

    let encodedDeviceId = URLSafeBase64.encode(Buffer.from(deviceId, 'utf8'))

    let values = {
      proto: 'DoH',
      dnssec: false,
      nolog: false,
      nofilter: false,
      addr: "",
      pk: "",
      providerName: "",
      hostname: `${process.env.VUE_APP_ENV === 'prod' ? 'doh' : 'devdoh'}.truple.workers.dev`,
      hash: "",
      path: `/${filterId}/${encodedDeviceId}/dns-query`
    }

    let props = (values.dnssec << 0) | (values.nolog << 1) | (values.nofilter << 2);
    let addr = values.addr.split("").map(c => c.charCodeAt());

    const dohStamp = () => {

      let v = [0x02, props, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00];
      v.push(addr.length, ...addr);

      let hash = Buffer.from(values.hash.replace(/[: \t]/g, ""), "hex");
      v.push(hash.length, ...hash);

      let hostname = values.hostname.split("").map(c => c.charCodeAt());
      v.push(hostname.length, ...hostname);

      let path = values.path.split("").map(c => c.charCodeAt());
      v.push(path.length, ...path);

      return `sdns://${URLSafeBase64.encode(Buffer.from(v))}`;
    }

    return dohStamp()
  }
}

export default (new SdnsStamp())