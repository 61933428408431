const filters = [
  {
    id: 'cloudflareAdult',
    name: '(Recommended) Cloudflare Adult Filter',
    description: 'Filters adult websites and malicious sites.',
    subDescription: 'Recommended for filtering',
    fallbackResolver: '1.1.1.3:53'
  },
  {
    id: 'cloudflare',
    name: 'Cloudflare',
    description: 'No filtering.  DNS Provided by Cloudflare',
    subDescription: 'Recommended for no filtering',
    fallbackResolver: '1.1.1.1:53'
  },
  {
    id: 'cloudflareSecurity',
    name: 'Cloudflare Security',
    description: 'Filters malicious websites that try to steal your information.  Does not filter adult websites.',
    subDescription: 'Recommended for very minimal filtering',
    fallbackResolver: '1.1.1.2:53'
  },
  {
    id: 'cleanbrowsingSecurity',
    name: 'Cleanbrowsing.org Security Filter',
    description: 'Filters malicious websites that try to steal your information.  Does not filter adult websites.',
    fallbackResolver: '185.228.168.9:53'
  },
  {
    id: 'cleanbrowsingAdult',
    name: 'Cleanbrowsing.org Adult Filter',
    description: 'Filters adult websites',
    fallbackResolver: '185.228.168.10:53'
  },
  {
    id: 'cleanbrowsingMixed',
    name: 'Cleanbrowsing.org Family Filter',
    description: 'Filters adult websites, vpns, proxies, some file sharing sites, reddit.com, and forces search engines & youtube to restricted search.',
    fallbackResolver: '185.228.168.168:53'
  },
  {
    id: 'cleanbrowsingExtreme',
    name: 'Truple Extreme Filter',
    description: 'Filters adult websites, vpns, proxies, file sharing sites, social media sites, lingerie sites, blocks search engines except for those that support restricted search.',
    fallbackResolver: '185.228.168.168:53'
  },
  {
    id: 'google',
    name: 'Google',
    description: 'No filtering.  DNS Provided by Google.',
    fallbackResolver: '8.8.8.8:53'
  }
]

export default filters